@import "Custom.scss";
//@import "./node_modules/animate.css";

$background-color:                #FAFAFB;

//.web-font {
//  font-family: 'Noto Sans SC', sans-serif !important;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//}

.hans-font {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Tahoma, Arial,
  'PingFang SC', 'Hiragino Sans GB', 'Heiti SC', 'Microsoft YaHei', 'WenQuanYi Micro Hei',sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hant-font {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Tahoma, Arial,
  'PingFang TC', 'Hiragino Sans GB', 'Heiti TC', 'Microsoft YaHei', 'WenQuanYi Micro Hei',sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  line-height: 1.75rem;
}

html, body {
  background-color: rgba(9,8,35,1);
  max-width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#navbar {
  .navbar-brand img {
    width: auto;
    height: 3.5rem;
  }
  select {
    padding: 0 0 0 0;
    border: 0;
    background-color: transparent;
  }

  select option {
    background-color: transparent !important;
    border: 0;
    color: #000;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  div > button > span {
    pointer-events: none;
  }
}

main {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

#main {
  position: relative;
  text-align: left;
  background-color: #FFFFFF;

  img {
    height: auto;
    width: 100%;
  }

  #welcome-title {
    position: relative;
  }

  .slogan {
    position: absolute;
    margin: 0 0 0 0;

    @include media-breakpoint-up(md) {
      top: 6%;
      right: 15%;
    }
    @include media-breakpoint-down(md) {
      top: 27%;
      right: 2%;
    }
  }

  #welcome-text {
    @include media-breakpoint-up(md) {
      position: absolute;
      top: 20%;
      left: 68%;
      text-align: left;
    }
    @include media-breakpoint-down(md) {
      position: relative;
      text-align: left;
      transform: translateX(50%);
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }
  }

  .text-purple-light {
    background: rgb(179,169,222);
    background: linear-gradient(90deg, rgba(179,169,222,1) 0%, rgba(198,200,232,1) 50%, rgba(224,225,242,1) 100%);
    //background: linear-gradient(to left, #A9B0D9, #D8DCF2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .welcome-text-bigger {
    @include media-breakpoint-up(md) {
      font-size: rfs-fluid-value(1.75rem);
    }
    @include media-breakpoint-down(md) {
      font-size: rfs-fluid-value(1.5rem);
      margin-left: -7rem;
    }
    font-weight: bold;
  }

  .welcome-text-small {
    @include media-breakpoint-up(md) {
      font-size: rfs-fluid-value(1.5rem);
    }
    @include media-breakpoint-down(md) {
      font-size: rfs-fluid-value(1.25rem);
      margin-left: -2rem;
    }
    font-weight: bolder;
  }

  .more-btn {
    @include media-breakpoint-up(md) {
      margin-top: 2rem !important;
    }
    @include media-breakpoint-down(md) {
      display: none;
      //margin-top: 1rem !important;
    }
    font-weight: bold;
  }

}

.btn-outline-primary:hover  {
  color: white;
}

.section-title-wrap {
  padding-top: 3.5rem;
  padding-bottom: 3rem;
  text-align: center;
}
.section-title {
  font-size: 1.5rem;
  color: $primary;
  //background: linear-gradient(to right, #D157F2, #5327F2);
  //-webkit-background-clip: text;
  //-webkit-text-fill-color: transparent;
}

.section-item-title {
  font-size: 1.25rem;
}

#intro {
  background-color: $background-color;
  h2 {
    @include media-breakpoint-up(md) {
      font-size: rfs-fluid-value(1.75rem);
    }
    @include media-breakpoint-down(md) {
      font-size: rfs-fluid-value(1.5rem);
    }
    font-weight: bold;
  }
  .intro p {
    @include media-breakpoint-up(md) {
      padding-left: 1rem;
    }
    font-size: 1rem;
    //color: #696687;
  }
}

#advantage {
  background-color: rgba(9,8,35,1);
}
.advantage {
  .section-item-title {
    font-weight: bold;
  }
  div {
    color: white;
  }
  p{
    font-size: 1rem;
    color: $gray-500;
  }
}

#certs, #steps, #blogs {
  background-color: $background-color;
  .certs {
    img {
      height: 100%;
    }
  }
  .steps {
    .card {
      background-color: $background-color;
      img {
        height: auto;
        width: 120px;
        @extend .mx-auto;
        @extend .mt-5;
      }
      .card-title {
        @extend .text-center;
      }
    }
  }
}

#services {
  background-color: $background-color;
}
.services {
  img.card-img-top {
    width: 256px;
    height: auto;
    @extend .mx-auto;
    @extend .my-3;
  }

  img.trail-icon {
    height: 2rem;
    width: auto;
    margin-left: 4px;
  }

  div.card-container {
    @extend .shadow;

    position: relative;
    perspective: 1000px;
    cursor: pointer;

    //transition: transform .5s ease-in-out;
    transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform-origin: 50% 50%;
    transform-style: preserve-3d;

    .back {
      .card-body {
        //color: white;
        //background: linear-gradient(to right, #D157F2, #5327F2);
        line-height: 1.25rem;
        li {
          margin-bottom: 0.365rem;
          @extend .text-muted;
        }
      }

      transform: rotateY(180deg);
    }

    .front, .back {
      //position: absolute;
      //top: 0;
      //left: 0;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
    }

    &.flip {
      transform: rotateY(180deg);
    }
  }

}

#services-tab {
  background-color: $background-color;
  table {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
}

#blogs {
  background-color: $background-color;
  img {
    height: auto;
    width: 100%;
  }
  .card-body a.link-dark:hover {
    color: $primary !important;
  }
  .section-title {
    background: linear-gradient(to right, #D157F2, #5327F2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .card-footer {
    border-top-style: none;
  }
}

#blog {
  .container {
    @include media-breakpoint-down(md){
      max-width: 768px !important;
    }
    @include media-breakpoint-up(md){
      max-width: 975px !important;
    }
    @include media-breakpoint-up(xxl){
      max-width: 1125px !important;
    }
  }

  background-color: $background-color;
  .container {
    h1 {
      font-weight: 700;
      padding-top: 1rem;
    }
    h2 {
      font-weight: 700;
      margin-top: 2.5rem !important;
    }
    h3 {
      @include font-size(1.5rem);
      font-weight: 500;
      margin-top: 1.5rem !important;
    }
    h4 {
      @include font-size(1.25rem);
      font-weight: 500;
      margin-top: 1.25rem !important;
    }
    dt {
      margin-top: 1rem !important;
    }
    dd {
      margin-top: 0.25rem !important;
    }
    p {
      line-height: 2rem;
    }
    strong {
      color: black;
      font-weight: 600;
    }
    li {
      margin-top: 1rem !important;
    }
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .blog-share-icon {
      cursor: pointer;
      margin-right: 2rem;
    }
  }
}

#faq {
  //background-color: rgba(9,8,35,1) !important;
  background-color: $background-color;
  .section-title {
    background: linear-gradient(to right, #D157F2, #5327F2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .faq {
    .section-item-title {
      color: $primary;
    }
  }
}

#contact {
  background-color: #FFFFFF;
}
.contact {
  blockquote p {
    background: linear-gradient(to right, #D157F2, #5327F2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  picture img {
    width: 100%;
    height: 100%;
  }
  .social_media {
    margin-right: 1.5rem;
    img {
      height: auto;
      width: 2.5rem;
      pointer-events: none;
    }
    cursor: pointer;
  }
}

footer {
  background-color: rgba(9,8,35,1);
  .footer-logo {
    max-width: 100%;
    max-height: 100%;
  }
  .send-icons a:hover {
    color: $primary !important;
  }
  div {
    color: white;
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background: linear-gradient(to right, rgba(235,236,247,0.8), rgba(175,179,227,0.8)) no-repeat;

  img.bar_code {
    width: 220px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  a {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #A9B0D9;
  }
  display: none;
  &.show{
    display: block;
  }
}




